import { Injectable }          from '@angular/core';
import { NavbarLinkComponent } from 'src/app/Component/Header/Navbar/NavbarLinkComponent';

@Injectable({ providedIn: 'root' })
export class NavbarService {
	sublinks: NavbarLinkComponent[] = [];

	registerSublink(sublink: NavbarLinkComponent) {
		if (this.sublinks.indexOf(sublink) > -1) {
			return;
		}

		this.sublinks.push(sublink);
		sublink.onOpen.subscribe(this.sublinkOpened.bind(this));
	}

	protected sublinkOpened(sl: NavbarLinkComponent) {
		for (const sublink of this.sublinks) {
			if (!sublink.submenu) {
				continue;
			}

			if (sublink === sl) {
				continue;
			}

			if (sublink.element.nativeElement.contains(sl.element.nativeElement)) {
				continue;
			}

			sublink.close();
		}
	}
}