import { Component, Input } from '@angular/core';
import { AuthService }      from '@user/Service/AuthService';
import { GroupDataService } from '@user/Service/GroupDataService';

@Component({
	selector: 'footer',
	templateUrl: 'FooterComponent.html'
})
export class FooterComponent {
	@Input() copyright = true;

	constructor(
		public auth: AuthService,
		protected group: GroupDataService
	) {
	}
}