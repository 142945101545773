<a href="" (click)="$event.preventDefault()">
	<i class="icon-bell2"></i>
	<span *ngIf="notification.length > 0">
		{{ notification.length }}
	</span>
</a>
<div class="top-cart-content" (click)="$event.stopPropagation();">
	<div class="top-cart-title">
		<h4>Powiadomienia</h4>
	</div>
	<div class="top-cart-items slim-scroll" style="overflow-y: auto; max-height: 275px">
		<div class="top-cart-item clearfix" *ngFor="let n of notification">
			<notification-item class="top-cart-item-desc"
					[n]="n"
			></notification-item>
		</div>
		<div *ngIf="notification.length === 0" class="text-center">
			Brak powiadomień
		</div>
	</div>
	<div class="top-cart-action clearfix">
		<span class="fleft top-checkout-price">
			<a href="" (click)="markAllAsRead($event)" style="min-width: 100px; margin-top: 5px">
				<i class="icon-check-double"></i> Przeczytane
			</a>
		</span>
		<button routerLink="/notification-settings" class="button button-3d button-small nomargin fright">
			Ustawienia
		</button>
	</div>
</div>