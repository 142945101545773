import { Converter }         from './Converter';
import { DataConverterInfo } from './DataConverterInfo';
import { DataDirection }     from './DataDirection';

export class Hydrator {
	static upperCaseFirst(s: string) {
		return s.substr(0, 1).toUpperCase() + s.substr(1);
	}

	static setProperty(entity: any, prop: string, value: any) {
		const setter = 'set' + Hydrator.upperCaseFirst(prop);
		if (entity[setter]) {
			entity[setter](value);
		}
		else {
			entity[prop] = value;
		}

		return entity;
	}

	static getProperty(entity: any, prop: string) {
		const getter = 'get' + Hydrator.upperCaseFirst(prop);
		if (entity[getter]) {
			return entity[getter]();
		}
		else {
			return entity[prop];
		}
	}

	static getClassProperties(entity: any) {
		const fieldTypes = Reflect.getMetadata('FieldType', entity);
		const keys = Object.keys(entity);

		for (const customKey in fieldTypes) {
			if (!fieldTypes.hasOwnProperty(customKey)) {
				continue;
			}

			if (keys.indexOf(customKey) > -1) {
				continue;
			}

			keys.push(customKey);
		}

		return keys;
	}

	/**
	 * Converts entity class to plain object, if toDatabase parameter is true, special handling of id fields
	 * will be applied
	 */
	static dehydrate<T>(entity: T, toDatabase = false) {
		if (typeof (entity as any).dehydrate === 'function') {
			return (entity as any).dehydrate(toDatabase);
		}

		const result: any = {};
		const fieldTypes = Reflect.getMetadata('FieldType', entity);
		const keys = Hydrator.getClassProperties(entity);

		for (const k of keys) {
			let value: any = Hydrator.getProperty(entity, k);
			let key = k;

			if (fieldTypes && fieldTypes[k]) {
				const fieldType: DataConverterInfo = fieldTypes[k];

				if (fieldType.translate) {
					key = fieldType.translate;
				}

				if (fieldType.translateDatabase && toDatabase) {
					key = fieldType.translateDatabase;
				}

				const dataDirection = toDatabase ? DataDirection.ToDatabase : DataDirection.ToPlain;
				value = Converter.convert(value, fieldType, dataDirection);
			}

			result[key] = value;
		}

		return result;
	}

	// noinspection JSUnusedGlobalSymbols
	static dehydrateArray<T>(entities: T[], toDatabase = false) {
		const out = [];
		for (const entity of entities) {
			out.push(Hydrator.dehydrate(entity, toDatabase));
		}

		return out;
	}

	static hydrate<T>(entity: T | (new() => T), data: any, fromDatabase = false): T {
		if (typeof entity === 'function') {
			entity = new (entity as any)() as T;
		}

		if (typeof (entity as any).hydrate === 'function') {
			return (entity as any).hydrate(data, fromDatabase) as T;
		}

		const fieldTypes = Reflect.getMetadata('FieldType', entity);
		const keys = Hydrator.getClassProperties(entity);

		for (const k of keys) {
			let key = k;
			let value;

			if (fieldTypes && fieldTypes[k]) {
				const fieldType: DataConverterInfo = fieldTypes[k];

				if (fieldType.translate) {
					key = fieldType.translate;
				}

				if (fieldType.translateDatabase && fromDatabase) {
					key = fieldType.translateDatabase;
				}

				value = data[key];

				const dataDirection = fromDatabase ? DataDirection.FromDatabase : DataDirection.FromPlain;
				value = Converter.convert(value, fieldType, dataDirection);
			}
			else {
				value = data[key];
			}

			// do not set undefined variables on partial data
			if (typeof value === 'undefined') {
				continue;
			}

			Hydrator.setProperty(entity, k, value);
		}

		return entity as T;
	}

	// noinspection JSUnusedGlobalSymbols
	static hydrateArray<T>(entityClass: new () => T, data: any[], fromDatabase = false): T[] {
		const result: T[] = [];
		for (const row of data) {
			result.push(Hydrator.hydrate<T>(entityClass, row, fromDatabase));
		}

		return result;
	}
}