import { DataDirection } from '@class-hydrator/DataDirection';

declare var BigInt: any;

export function convertBigInt(value: any, dataDirection: DataDirection) {
	switch (dataDirection) {
		case DataDirection.FromPlain:
			return value !== null ? BigInt(value) : null;
		case DataDirection.FromDatabase:
			return value;
		case DataDirection.ToPlain:
			return typeof value === 'bigint' ? value.toString() : value;
		case DataDirection.ToDatabase:
			return value;
	}

	return null;
}