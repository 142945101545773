import { DownloadResponse } from '@core/Interface/DownloadResponse';
import { saveAs }           from 'file-saver';

export interface FileInterface {
	fileName: string;
	content: string;
}

export class DownloadHelper {
	static base64ToBlob(content: string, contentType = null, sliceSize = 512) {
		const byteCharacters = window.atob(content);
		const byteArrays = [];

		for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
			const slice = byteCharacters.slice(offset, offset + sliceSize);

			const byteNumbers = new Array(slice.length);
			for (let i = 0; i < slice.length; i++) {
				byteNumbers[i] = slice.charCodeAt(i);
			}

			const byteArray = new Uint8Array(byteNumbers);
			byteArrays.push(byteArray);
		}

		return new Blob(byteArrays, { type: contentType });
	}

	static hexToBlob(content: string, contentType = null) {
		const bytes = [];
		for (let i = 0; i < content.length; i += 2) {
			const b = parseInt(content.slice(i, i + 2), 16);
			bytes.push(b);
		}

		const byteArrays = new Uint8Array(bytes);
		return new Blob([byteArrays], { type: contentType });
	}

	static async downloadHexFile(hexContent: string, fileName: string) {
		const file = DownloadHelper.hexToBlob(hexContent);
		saveAs(file, fileName);
		await new Promise(r => setTimeout(r, 1000));
	}

	static async downloadFile(response: DownloadResponse, contentType = null) {
		if (response.content) {
			const file = DownloadHelper.base64ToBlob(response.content, contentType);
			saveAs(file, response.fileName);
			await new Promise(r => setTimeout(r, 1000));
		}

		if (response.files) {
			for (const f of response.files) {
				const file = DownloadHelper.base64ToBlob(f.content, contentType);
				saveAs(file, f.fileName);
				await new Promise(r => setTimeout(r, 1000));
			}
		}
	}

	static async readFile(file: File): Promise<FileInterface> {
		if (file === null) {
			return null;
		}

		const resultBase64 = await new Promise((resolve) => {
			const reader = new FileReader();
			let fileContent = '';

			reader.onload = () => {
				const view = new Uint8Array(reader.result as ArrayBuffer);
				let charBytes = '';
				for (const chrByte of view) {
					charBytes += String.fromCharCode(chrByte);
				}

				fileContent = btoa(charBytes);
				resolve(fileContent);
			};

			reader.readAsArrayBuffer(file);
		});

		return {
			fileName: file.name,
			content: resultBase64 as string
		};
	}
}
