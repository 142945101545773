import { FieldType } from '@class-hydrator/Decorators';
import * as moment   from 'moment';

export enum NotificationResolution {
	FULL, // both push and saved
	SEND,
	HIDE,
	SAVE
}

export class NotificationModel {
	id: string = null;

	pCreatedAtLocalText: string;

	title = '';
	name = '';
	message = '';
	type = '';
	data: any = {};
	isRead = false;

	@FieldType({ type: 'moment' })
	createdAt: moment.Moment = null;

	userId: string = null;

	get createdAtLocalText() {
		if (this.pCreatedAtLocalText) {
			return this.pCreatedAtLocalText;
		}

		return this.pCreatedAtLocalText = this.createdAt.clone().local().format('YYYY-MM-DD HH:mm');
	}
}