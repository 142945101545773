import { NgModule }                                from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { PageNotFoundComponent }                   from 'src/app/Component/PageNotFound/PageNotFoundComponent';

const routes: Routes = [

	{
		path: '',
		loadChildren: () => import('@home/HomeModule').then(m => m.HomeModule)
	},
	{
		path: '',
		loadChildren: () => import('@news/NewsModule').then(m => m.NewsModule)
	},
	{
		path: '',
		loadChildren: () => import('@user/UserModule').then(m => m.UserModule)
	},
	{
		path: '',
		loadChildren: () => import('@organization/OrganizationModule').then(m => m.OrganizationModule)
	},
	{
		path: '',
		loadChildren: () => import('@event/EventModule').then(m => m.EventModule)
	},
	{
		path: '',
		loadChildren: () => import('@mail/MailModule').then(m => m.MailModule)
	},
	{
		path: '',
		loadChildren: () => import('@sms/SmsModule').then(m => m.SmsModule)
	},
	{
		path: '',
		loadChildren: () => import('@chat/ChatModule').then(m => m.ChatModule)
	},
	{
		path: '',
		loadChildren: () => import('@notification/NotificationModule').then(m => m.NotificationModule)
	},
	{
		path: '',
		loadChildren: () => import('@admin/AdminModule').then(m => m.AdminModule)
	},
	{
		path: '**',
		component: PageNotFoundComponent
	}

];

@NgModule({
	imports: [
		RouterModule.forRoot(routes, {
			onSameUrlNavigation: 'reload',
			scrollPositionRestoration: 'top'
		})
	],
	exports: [RouterModule]
})
export class AppRoutingModule {
}
