<div id="header-wrap">
	<div class="container clearfix">

		<div id="primary-menu-trigger" (click)="openMenu()">
			<i class="icon-reorder"></i>
		</div>

		<div id="logo">
			<a routerLink="/">
				<img src="/assets/images/logos/logo-wisla.svg" alt="Wisla biznes" style="margin: 0 auto;"
						loading="lazy">
			</a>
		</div>

		<div id="top-account" class="dropdown" dropdown>
			<a (click)="$event.preventDefault()" class="btn btn-secondary btn-sm dropdown-toggle"
					style="color: #fff;"
					dropdownToggle>
				<i class="icon-user"></i>
			</a>

			<div *dropdownMenu class="dropdown-menu dropdown-menu-right">
				<ng-container *ngIf="auth.user">
					<a class="dropdown-item tleft" routerLink="/profile">
						<i class="icon-user"></i> Profil
					</a>
					<a class="dropdown-item tleft" routerLink="/edit-profile">
						<i class="icon-user-cog"></i> Ustawienia
					</a>
					<a class="dropdown-item tleft" routerLink="/chat">
						<i class="icon-chat"></i> Chat
					</a>
					<a class="dropdown-item tleft" routerLink="/mail-compose">
						<i class="icon-envelope21"></i> Wiadomości
					</a>
					<div class="dropdown-divider"></div>
					<a class="dropdown-item tleft" href="" (click)="logout($event)">
						<i class="icon-signout"></i> Wyloguj
					</a>
				</ng-container>
				<a *ngIf="!auth.user" class="dropdown-item tleft" routerLink="/auth">
					<i class="icon-signin"></i> Zaloguj
				</a>
			</div>
		</div>

		<notifications id="top-cart" class="hide-on-mobile mr-2" *ngIf="auth.isAuthenticated()">
		</notifications>

		<nav id="primary-menu" [(visibleMobileMenu)]="visibleMobileMenu"></nav>
	</div>
</div>
