import { Component, ElementRef, EventEmitter, HostBinding, HostListener, Input, OnInit, Output } from '@angular/core';
import { Router }                                                                                from '@angular/router';
import { NavbarService }                                                                         from '@core/Service/NavbarService';

@Component({
	selector: 'li[sublink]',
	template: `
		<a href="#" (click)="mouseClick($event)">
			<ng-content select="[slot=text]"></ng-content>
		</a>
		<ul *ngIf="submenu" [class.d-block]="submenuVisible">
			<ng-content select="[slot=submenu]"></ng-content>
		</ul>
	`
})
export class NavbarLinkComponent {
	@Input() routerLink: any = null;

	@HostBinding('class.sub-menu')
	@Input() submenu = false;

	@Output() onOpen = new EventEmitter<NavbarLinkComponent>();
	@Output() onClose = new EventEmitter<NavbarLinkComponent>();

	submenuVisible = false;
	closeTimeout: any;

	mobile = false;

	constructor(
		public element: ElementRef,
		protected router: Router,
		protected navbarService: NavbarService
	) {
		this.navbarService.registerSublink(this);
		const mediaQuery = window.matchMedia('(min-width: 991px)');
		this.mobile = !mediaQuery.matches;
		try {
			// Chrome & Firefox
			mediaQuery.addEventListener('change', (e) => {
				this.mobile = !e.matches;
			});
		}
		catch (e1) {
			try {
				// Safari
				// noinspection JSDeprecatedSymbols
				mediaQuery.addListener((e) => {
					this.mobile = !e.matches;
				});
			}
			catch (e2) {
				console.error(e2);
			}
		}

	}

	@HostListener('mouseenter', ['$event'])
	mouseEnter($event: MouseEvent) {
		if (this.mobile) {
			return;
		}

		this.submenuVisible = true;
		this.onOpen.emit(this);
	}

	@HostListener('mouseleave', ['$event'])
	mouseLeave($event: MouseEvent) {
		if (this.mobile) {
			return;
		}

		clearTimeout(this.closeTimeout);
		this.closeTimeout = setTimeout(() => {
			this.close();
		}, 500);
	}

	close() {
		clearTimeout(this.closeTimeout);
		this.submenuVisible = false;
		this.onClose.emit(this);
	}

	mouseClick($event: MouseEvent) {
		$event.preventDefault();

		if (!this.submenu) {
			this.router.navigate(this.routerLink).catch(console.error);
			return;
		}

		this.submenuVisible = !this.submenuVisible;
		if (this.submenuVisible) {
			this.onOpen.emit(this);
		}
		else {
			this.onClose.emit(this);
		}
	}
}