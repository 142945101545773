import { DataConverterInfo } from './DataConverterInfo';
import { DataDirection }     from './DataDirection';

export class Converter {
	static converters: { [type: string]: (value: any, dataDirection: DataDirection) => any } = {};

	static registerConverter(type: string, converter: (value: any, dataDirection: DataDirection) => any) {
		if (Converter.converters[type]) {
			throw new Error(`Converter of type: ${type} is already registered`);
		}

		Converter.converters[type] = converter;
	}

	static convert(value: any, info: DataConverterInfo, direction: DataDirection) {
		if (info.array) {
			const out = [];
			if (value === null || value === undefined) {
				value = [];
			}

			for (const val of value) {
				out.push(Converter.convertSingle(val, info, direction));
			}

			value = out;
		}
		else {
			value = Converter.convertSingle(value, info, direction);
		}

		return value;
	}

	static convertSingle(value: any, info: DataConverterInfo, direction: DataDirection) {
		if (typeof value === 'undefined') {
			return undefined;
		}

		if (info.converter) {
			return info.converter(value, direction, info.type);
		}

		if (!Converter.converters[info.type]) {
			throw new Error(`Unrecognized converter type: ${info.type}`);
		}

		return Converter.converters[info.type](value, direction);
	}
}