import { DataConverterInfo } from './DataConverterInfo';
import 'reflect-metadata';

export function FieldType(opts: DataConverterInfo) {
	return (target: any, propertyKey: string | symbol) => {
		const allMetadata = Reflect.getMetadata('FieldType', target) || {};
		allMetadata[propertyKey] = opts;

		Reflect.defineMetadata('FieldType', allMetadata, target);
	};
}