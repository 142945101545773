import { moment, Moment } from '@core/Model/Moment';

export class Timer {
	interval: any = null;
	callback: any;
	at: Moment;

	get ticking() {
		return !!this.interval;
	}

	constructor(public id: string) {
		this.load();
	}

	scheduleAt(callback: any, at: Moment) {
		if (this.interval) {
			clearInterval(this.interval);
		}
		this.callback = callback;
		this.at = at;
		this.interval = setInterval(this.tick.bind(this), 1000);
		this.save();
	}

	save() {
		const d = {
			id: this.id,
			callback: this.callback,
			at: this.at.toISOString()
		};

		localStorage.setItem('vt-' + this.id, JSON.stringify(d));
	}

	load() {
		const s = localStorage.getItem('vt-' + this.id);
		if (!s) {
			return;
		}

		const d = JSON.parse(s);
		this.id = d.id;
		this.callback = d.callback;
		this.at = moment(d.at);

		if (this.interval) {
			clearInterval(this.interval);
		}
		this.interval = setInterval(this.tick.bind(this), 1000);
	}

	tick() {
		const m = moment();
		if (m.isSameOrAfter(this.at)) {
			clearInterval(this.interval);
			if (!window[this.callback]) {
				return;
			}

			(window as any)[this.callback]();
		}
	}

	cancel() {
		if (this.interval) {
			clearInterval(this.interval);
		}
		localStorage.removeItem('vt-' + this.id);
	}
}