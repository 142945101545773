<div id="wrapper" class="clearfix" style="opacity: 1;">

	<!-- Header
	============================================= -->
	<header [ngClass]="{'d-none': hideFooterHeader, 'd-sm-block': hideFooterHeader}" id="header" class="full-header"></header>
	<!-- #header end -->


	<router-outlet></router-outlet>


	<!-- Footer
	============================================= -->
	<footer [ngClass]="{'d-none': hideFooterHeader, 'd-sm-block': hideFooterHeader}" id="footer" class="dark"
			[copyright]="false"
	></footer><!-- #footer end -->
</div>

<div class="css3-spinner" #preloader style="opacity: 1; display: block">
	<div class="css3-spinner-flipper"></div>
</div>