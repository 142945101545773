import {
	HttpInterceptor,
	HttpRequest,
	HttpHandler,
	HttpEvent, HttpResponse
}                      from '@angular/common/http';
import { AuthService } from '@user/Service/AuthService';
import { Observable }  from 'rxjs';
import { Injectable }  from '@angular/core';
import { tap }         from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class AuthInterceptor implements HttpInterceptor {

	constructor(protected authService: AuthService) {}

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		if (!this.authService.token) {
			return next.handle(req);
		}

		const cloneReq = req.clone({
			headers: req.headers.set(
				'Authorization',
				'Bearer ' + this.authService.token
			)
		});

		return next.handle(cloneReq).pipe(tap(this.refreshToken.bind(this)));
	}

	refreshToken(event: HttpEvent<any>) {
		if (event instanceof HttpResponse) {
			const newToken = event.headers.get('x-new-token');
			if (!newToken) {
				return;
			}

			let decoded;
			try {
				decoded = JSON.parse(newToken);
			} catch (e) {
				console.log('Unable to decode token');
				return;
			}

			this.authService.setToken(decoded.token, decoded.expiresAt);
		}
	}
}