<div id="copyrights">
	<div class="container clearfix">
		<div class="col_half">
			Copyrights © 2020 All Rights Reserved by Business Club 1906.<br>
			Projekt i wykonanie: <a target="_blank" href="https://td-el.com/">TechDesign</a>
		</div>
		<div class="col_half col_last tright">
			<div class="copyrights-menu copyright-links fright clearfix">
				<a class="ml-5" routerLink="/">Strona główna </a>
				<a routerLink="/news"> News</a>
				<a routerLink="/contact">Partnerstwo</a>
			</div>

			<div class="fright clearfix">
				<a target="_blank" href="https://www.facebook.com/WislaBiznes/" class="social-icon si-small si-borderless nobottommargin si-facebook">
					<i class="icon-facebook"></i>
					<i class="icon-facebook"></i>
				</a>

				<a target="_blank" href="https://www.linkedin.com/company/wis-a-krak-w-sa" class="social-icon si-small si-borderless nobottommargin si-linkedin">
					<i class="icon-linkedin"></i>
					<i class="icon-linkedin"></i>
				</a>
			</div>
		</div>
	</div>
</div>