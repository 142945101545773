import { Component, OnInit, ViewChild } from '@angular/core';
import { Router }                       from '@angular/router';
import { GenericResponse }              from '@core/Interface/GenericResponse';
import { CoreHttp }                     from '@core/Service/CoreHttp';
import { AuthService }                  from '@user/Service/AuthService';
import { config }                       from 'src/app/config';

@Component({
	selector: 'header',
	templateUrl: 'HeaderComponent.html',
	styleUrls: ['HeaderComponent.scss']
})
export class HeaderComponent {
	visibleMobileMenu = false;

	@ViewChild('primaryMenu', { static: false })
	protected megaMenu: any;

	constructor(
		public auth: AuthService,
		protected router: Router,
		protected coreHttp: CoreHttp
	) {
	}

	async logout($event: MouseEvent) {
		$event.preventDefault();
		await this.auth.logout();
		await this.router.navigateByUrl('/');
	}

	openMenu() {
		this.visibleMobileMenu = !this.visibleMobileMenu;
	}
}