import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule }                            from '@angular/core';
import { FormsModule, ReactiveFormsModule }    from '@angular/forms';
import { BrowserModule }                       from '@angular/platform-browser';
import { BrowserAnimationsModule }             from '@angular/platform-browser/animations';
import { Superfish }                           from '@core/Directive/Superfish';
import { SplashScreen }                        from '@ionic-native/splash-screen/ngx';
import { StatusBar }                           from '@ionic-native/status-bar/ngx';
import { IonicModule }                         from '@ionic/angular';
import { NgSelectModule }                      from '@ng-select/ng-select';
import { BsDropdownModule }                    from 'ngx-bootstrap/dropdown';
import { ModalModule }                         from 'ngx-bootstrap/modal';
import { NavbarComponent }                     from 'src/app/Component/Header/Navbar/NavbarComponent';
import { NavbarLinkComponent }                 from 'src/app/Component/Header/Navbar/NavbarLinkComponent';
import { NotificationItemComponent }           from 'src/app/Component/Header/Notifications/NotificationItem/NotificationItemComponent';
import { NotificationsComponent }              from 'src/app/Component/Header/Notifications/NotificationsComponent';
import { config }                              from './config';
import { AppComponent }                        from './AppComponent';
import { AppRoutingModule }                    from './AppRoutingModule';
import { FooterComponent }                     from './Component/Footer/FooterComponent';
import { HeaderComponent }                     from './Component/Header/HeaderComponent';
import { PageNotFoundComponent }               from './Component/PageNotFound/PageNotFoundComponent';
import { AuthInterceptor }                     from './Core/Service/AuthInterceptor';
import { FirebaseX } from "@ionic-native/firebase-x/ngx";


const imports = [
	BrowserModule,
	BrowserAnimationsModule,
	HttpClientModule,
	ModalModule,
	AppRoutingModule,
	BsDropdownModule.forRoot(),
	FormsModule,
	ReactiveFormsModule,
	NgSelectModule,
	IonicModule.forRoot()
];

@NgModule({
	declarations: [
		AppComponent, FooterComponent, HeaderComponent, PageNotFoundComponent,
		Superfish, NavbarComponent, NavbarLinkComponent, NotificationsComponent, NotificationItemComponent
	],
	entryComponents: [],
	imports: imports,
	providers: [StatusBar, SplashScreen, FirebaseX, {
		provide: HTTP_INTERCEPTORS,
		useClass: AuthInterceptor,
		multi: true
		// provide: RouteReuseStrategy
		// useClass: IonicRouteStrategy
		}
	],
	bootstrap: [AppComponent]
})
export class AppModule {
}

