import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml }   from '@angular/platform-browser';
import { NotificationModel }        from '@notification/Model/NotificationModel';
import { PushNotificationService }  from '@notification/Service/PushNotificationService';

@Component({
	selector: 'notification-item',
	templateUrl: 'NotificationItemComponent.html'
})
export class NotificationItemComponent implements OnInit {
	@Input() n: NotificationModel;
	safeHtml: SafeHtml;

	constructor(
		protected sanitizer: DomSanitizer,
		public pushNotification: PushNotificationService,
	) {
	}

	ngOnInit(): void {
		this.safeHtml = this.sanitizer.bypassSecurityTrustHtml(this.n.message);
	}

	getNotificationLink(n: NotificationModel) {
		switch (n.type) {
			case 'chat-message':
				return ['/chat', n.data.conversationId];
			case 'chat-invite':
				return ['/chat/conversation/invitations'];
		}

		return [];
	}

	async readNotification(n: NotificationModel) {
		await this.pushNotification.markAsRead(n.id);
	}
}