export type ListenerInterfaceAsync = (...data: any[]) => Promise<void>;

export class EventManagerAsync {
	listeners: { [name: string]: ListenerInterfaceAsync[] } = {};

	async fire(eventName: string, ...data: any[]) {
		const listeners = this.listeners[eventName];
		if (!listeners) {
			return;
		}

		for (const listener of listeners) {
			await listener(...data);
		}
	}

	on(eventName: string, callback: ListenerInterfaceAsync) {
		if (!this.listeners[eventName]) {
			this.listeners[eventName] = [];
		}

		this.listeners[eventName].push(callback);
	}
}