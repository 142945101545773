import { Injectable }                                 from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { GenericResponse }                            from '@core/Interface/GenericResponse';
import { moment }                                     from '@core/Model/Moment';
import { DateHelper }                                 from '@core/Service/DateHelper';
import { DownloadHelper }                             from '@core/Service/DownloadHelper';

@Injectable({ providedIn: 'root' })
export class CoreHttp {
	constructor(
		protected httpClient: HttpClient
	) {
	}

	// noinspection JSMethodCanBeStatic
	public getHeaders(hasFiles = false) {
		let headers: HttpHeaders;
		if (hasFiles) {
			headers = new HttpHeaders()
				.set('Accept', 'application/json');
		}
		else {
			headers = new HttpHeaders()
				.set('Content-Type', 'application/json');
		}

		return headers;
	}

	// noinspection JSMethodCanBeStatic
	checkResponse(response: GenericResponse) {
		return response.success || false;
	}

	// noinspection JSMethodCanBeStatic
	isIterable(obj: any) {
		if (obj === null || obj === undefined) {
			return false;
		}

		return !(['number', 'string', 'boolean'].indexOf(typeof obj) > -1 || !obj.hasOwnProperty);
	}

	dataHasFiles(data: any) {
		if (!this.isIterable(data)) {
			return false;
		}

		for (const key in data) {
			if (!data.hasOwnProperty(key)) {
				continue;
			}

			const value = data[key];
			if (value instanceof File) {
				return true;
			}

			if (this.isIterable(value)) {
				if (this.dataHasFiles(value)) {
					return true;
				}
			}
		}

		return false;
	}

	convertToFormData(obj: any, form?: FormData, namespace?: string): FormData {
		const formData = form || new FormData();
		let formKey;

		for (const property in obj) {
			if (obj.hasOwnProperty(property) && obj[property]) {
				if (namespace) {
					formKey = namespace + '[' + property + ']';
				}
				else {
					formKey = property;
				}

				const value = obj[property];
				// if the property is an object, but not a File, use recursivity.
				if (value instanceof Date) {
					formData.append(formKey, DateHelper.dateToString(value));
				}
				else if (moment.isMoment(value)) {
					formData.append(formKey, DateHelper.dateToString(value));
				}
				else if (typeof value === 'object' && !(value instanceof File)) {
					this.convertToFormData(value, formData, formKey);
				}
				else { // if it's a string or a File object
					formData.append(formKey, value);
				}
			}
		}

		return formData;
	}

	async sanitizeData(obj: any) {
		for (const property in obj) {
			if (!obj.hasOwnProperty(property) || !obj[property]) {
				continue;
			}

			const value = obj[property];

			// if the property is an object, but not a File, use recursivity.
			if (value instanceof Date || moment.isMoment(value)) {
				obj[property] = DateHelper.dateToString(value);
			}
			else if (value instanceof File) {
				obj[property] = await DownloadHelper.readFile(value);
			}
			else if (typeof value === 'object' && value !== null && value.hasOwnProperty) {
				obj[property] = await this.sanitizeData(value);
			}
		}

		return obj;
	}

	async makeRequest<Type>(url: string, data?: any, method = 'post'): Promise<Type> {
		if (!data) {
			data = {};
		}

		const hasFiles = this.dataHasFiles(data);
		if (hasFiles) {
			data = this.convertToFormData(data);
		}
		else {
			data = await this.sanitizeData(data);
		}

		let response;
		try {
			const options: any = { headers: this.getHeaders(hasFiles) };
			if (data) {
				options.body = data;
			}

			response = await this
				.httpClient
				.request<Type | GenericResponse>(method, url, options)
				.toPromise();
		}
		catch (e) {
			if (e instanceof HttpErrorResponse) {
				if (e.error instanceof ProgressEvent) {
					response = { success: false };
				} else {
					response = e.error;
				}
			} else {
				console.log(e);
				response = { success: false, message: e.message };
			}
		}

		if (response && !this.checkResponse(response as GenericResponse)) {
			return response as Type;
		}

		return response as Type;
	}
}
