import { Injectable }                                from '@angular/core';
import { Router }                                    from '@angular/router';
import { Conversation }                              from '@chat/Model/Conversation';
import { NotificationModel, NotificationResolution } from '@notification/Model/NotificationModel';
import { FirebaseNotificationService }               from '@notification/Service/FirebaseNotificationService';
import { VisibilityService }                         from '@notification/Service/VisibilityService';

@Injectable({ providedIn: 'root' })
export class ChatService {
	currentConversation: Conversation;

	constructor(
		protected firebaseNotification: FirebaseNotificationService,
		protected visibilityService: VisibilityService,
		protected router: Router
	) {}

	initialize() {
		this.firebaseNotification.em.on('notification:before-send', this.notificationBeforeSend.bind(this));
	}

	async notificationBeforeSend(data: {resolution: NotificationResolution, notification: NotificationModel}) {
		if (!this.visibilityService.visible.value) {
			data.resolution = NotificationResolution.FULL;
			return;
		}

		// Do not care about any other than chat messages
		if (data.notification.type !== 'chat-message') {
			return;
		}

		// If we are not on chat page, full notify
		if (!this.router.url.startsWith('/chat')) {
			data.resolution = NotificationResolution.FULL;
			return;
		}

		const conversationId = data.notification.data.conversationId;

		// We are on the same conversation page
		if (conversationId && this.currentConversation && this.currentConversation === conversationId) {
			data.resolution = NotificationResolution.HIDE;
		} else {
			// Not on same page? Just save it in top page
			data.resolution = NotificationResolution.SAVE;
		}
	}
}