import { Component, ElementRef, EventEmitter, HostBinding, HostListener, Input, OnInit, Output } from '@angular/core';
import { Event, NavigationStart, Router }                                                        from '@angular/router';
import { Hydrator }                                                                              from '@class-hydrator/Hydrator';
import { Group }                                                                                 from '@user/Model/Group';
import { AuthService }                                                                           from '@user/Service/AuthService';
import { GroupDataService }                                                                      from '@user/Service/GroupDataService';

@Component({
	selector: 'nav',
	templateUrl: 'NavbarComponent.html',
	styleUrls: ['NavbarComponent.scss']
})
export class NavbarComponent implements OnInit {
	groups: Group[] = [];

	@HostBinding('class.d-block')
	@Input() visibleMobileMenu = false;
	@Output() visibleMobileMenuChange = new EventEmitter<boolean>();

	constructor(
		public authService: AuthService,
		protected groupDataService: GroupDataService,
		protected element: ElementRef,
		protected router: Router
	) {}

	ngOnInit(): void {
		this.loadGroups().catch(console.error);
		this.authService.userSubject.subscribe(() => {
			this.loadGroups().catch(console.error);
		});

		this.router.events.subscribe((e: Event) => {
			if (e instanceof NavigationStart) {
				this.visibleMobileMenu = false;
				this.visibleMobileMenuChange.emit(this.visibleMobileMenu);
			}
		});
	}

	@HostListener('document:click', ['$event'])
	onDocumentClick($event: MouseEvent) {
		// Skip on menu trigger
		const target = $event.target as HTMLElement;
		if (target.id === 'primary-menu-trigger' || target.parentElement.id === 'primary-menu-trigger') {
			return;
		}

		if (!this.element.nativeElement.contains($event.target)) {
			this.visibleMobileMenu = false;
			this.visibleMobileMenuChange.emit(this.visibleMobileMenu);
		}
	}

	async loadGroups() {
		const response = await this.groupDataService.all();
		if (!response.success) {
			return;
		}

		this.groups = [];
		for (const g of response.entities) {
			this.groups.push(Hydrator.hydrate(new Group(), g));
		}
	}
}