import { EventEmitter, Injectable } from '@angular/core';
import { CoreHttp }                 from '@core/Service/CoreHttp';
import { CrudResponse }             from '@core/Interface/CrudResponse';

@Injectable({ providedIn: 'root' })
export class CrudDataService {
	apiUrl: string;

	integrityChange = new EventEmitter<{ action: string, data: any }>();
	public lastQuery: any;

	constructor(
		protected coreHttp: CoreHttp
	) {
	}

	/** CRUD METHODS */

	/**
	 * Get a single record
	 */
	async get(query: any) {
		return await this.coreHttp.makeRequest<CrudResponse>(`${ this.apiUrl }/get`, query, 'post');
	}

	/**
	 * Gets a list of entities
	 */
	async list(query: any) {
		const response = await this
			.coreHttp
			.makeRequest<CrudResponse>(`${ this.apiUrl }/list`, query);

		if (response && response.success) {
			this.lastQuery = query;
		}

		return response;
	}

	/**
	 * Gets all entities
	 */
	async all(light = false) {
		return await this
			.coreHttp
			.makeRequest<CrudResponse>(`${ this.apiUrl }/all`, { light: light }, 'get');
	}

	/**
	 * Refreshes last get method
	 */
	async repeatList() {
		if (!this.lastQuery) {
			throw Error('Never executed query before');
		}

		return await this.list(this.lastQuery);
	}

	/**
	 * Save entity, update it in dataChange and pass response
	 */
	async save(item: any) {
		const response = await this
			.coreHttp
			.makeRequest<CrudResponse>(`${ this.apiUrl }/save`, item, 'put');

		if (response && response.success) {
			this.integrityChange.emit({ action: 'save', data: response.entity });
		}

		return response;
	}

	/**
	 * Save entity, update it in dataChange and pass response
	 */
	async patch(entityId: string, property: string, newValue: any) {
		const response = await this
			.coreHttp
			.makeRequest<CrudResponse>(`${ this.apiUrl }/patch`, {
				id: entityId,
				property: property,
				newValue: newValue
			}, 'patch');

		if (response && response.success) {
			this.integrityChange.emit({ action: 'save', data: response.entity });
		}

		return response;
	}

	/**
	 * Delete specific record
	 */
	async delete(item: any) {
		const response = await this
			.coreHttp
			.makeRequest<CrudResponse>(`${ this.apiUrl }/delete`, item, 'delete');

		if (response && response.success && response.entity) {
			this.integrityChange.emit({ action: 'save', data: response.entity });
		}

		return response;
	}

	/**
	 * Execute mass action and refresh entities
	 */
	async mass(action: string, ids: string[], additionalData?: any) {
		const data = Object.assign({ action: action, ids: ids }, additionalData || {});
		const response = await this
			.coreHttp
			.makeRequest<CrudResponse>(`${ this.apiUrl }/mass`, data);

		if (response && response.success) {
			this.integrityChange.emit({ action: 'mass' + action, data: response.entity });
		}

		return response;
	}
}
