import { enableProdMode }         from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { Converter }              from '@class-hydrator/Converter';
import { convertBigInt }          from '@class-hydrator/Converter/BigInt';
import { convertMoment }          from '@class-hydrator/Converter/Moment';
import { convertNumber }          from '@class-hydrator/Converter/Number';
import { moment }                 from '@core/Model/Moment';
import 'moment/locale/pl';
import { AppModule }              from 'src/app/AppModule';

import { environment } from './environments/environment';
import 'reflect-metadata';

if (environment.production) {
	enableProdMode();
}

moment().locale('pl');
moment.updateLocale('pl', {
	monthsShort: 'Sty_Lut_Mar_Kwi_Maj_Cze_Lip_Sie_Wrz_Paź_Lis_Gru'.split('_'),
	weekdays: 'Niedziela_Poniedziałek_Wtorek_Środa_Czwartek_Piątek_Sobota'.split('_'),
	weekdaysShort: 'Ndz_Pon_Wt_Śr_Czw_Pt_Sob'.split('_')
});

Converter.registerConverter('moment', convertMoment);
Converter.registerConverter('number', convertNumber);
Converter.registerConverter('bigint', convertBigInt);

platformBrowserDynamic()
	.bootstrapModule(AppModule)
	.catch(err => console.error(err));
