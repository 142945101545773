import { AfterViewInit, Component, ElementRef, NgZone, Renderer2, ViewChild } from '@angular/core';
import { NavigationEnd, Router, RouterEvent }                                 from '@angular/router';
import { ChatService }                                                        from '@chat/Service/ChatService';
// import { SplashScreen }                                                       from '@ionic-native/splash-screen/ngx';
// import { StatusBar }                                                          from '@ionic-native/status-bar/ngx';

import { Platform }                    from '@ionic/angular';
import { FirebaseNotificationService } from '@notification/Service/FirebaseNotificationService';
import { PushNotificationService }     from '@notification/Service/PushNotificationService';

import { Subject } from 'rxjs';

declare var $: any;

@Component({
	selector: 'app-root',
	templateUrl: './AppComponent.html',
	styleUrls: ['./AppComponent.scss']
})
export class AppComponent implements AfterViewInit {
	loadingSubject = new Subject<boolean>();
	@ViewChild('preloader', { static: false }) preloader: ElementRef;
	loading = true;
	hideFooterHeader = false;

	constructor(
		protected platform: Platform,
		protected ngZone: NgZone,
		public router: Router,
		protected renderer: Renderer2,
		protected firebaseNoti: FirebaseNotificationService,
		protected push: PushNotificationService,
		protected chat: ChatService,
	) {
		this.initializeApp();
		router.events.subscribe(this.navigationInterceptor.bind(this));
		this.loadingSubject.subscribe(this.updatePreloader.bind(this));
		this.router.events.subscribe(e => {
			if (e instanceof NavigationEnd) {
				this.hideFooterHeader = e.url.startsWith('/chat');
			}
		});
	}

	initializeApp() {
		this.platform.ready().then(() => {
			// this.statusBar.styleDefault();
			// this.splashScreen.hide();
			this.loadingSubject.next(false);
			this.firebaseNoti.init().catch(console.error);
			this.push.initialize().catch(console.error);
			this.chat.initialize();
		});
	}

	navigationInterceptor(event: RouterEvent): void {
		// if (event instanceof NavigationStart) {
		// 	this.loadingSubject.next(true);
		// }
		// if (event instanceof NavigationEnd) {
		// 	this.loadingSubject.next(false);
		// }
		//
		// // Set loading state to false in both of the below events to hide the spinner in case a request fails
		// if (event instanceof NavigationCancel) {
		// 	this.loadingSubject.next(false);
		// }
		// if (event instanceof NavigationError) {
		// 	this.loadingSubject.next(false);
		// }
	}

	updatePreloader(v: boolean) {
		if (v === this.loading) {
			return;
		}
		this.loading = v;
		console.log('NAVIGATION CHANGED', this.loading);
		const ne = this.preloader.nativeElement;
		this.ngZone.runOutsideAngular(() => {
			if (v) {
				// is loading
				ne.style.display = 'block';
				$(ne).animate({ opacity: 1 }, 500);
				console.log('starting loading', ne);
			}
			else {
				// loading finished
				console.log('finished loading', ne);
				$(ne).animate({ opacity: 0 }, 500, () => { ne.style.display = 'none'; });
			}
		});

	}

	ngAfterViewInit(): void {
		// this.ngZone.runOutsideAngular(() => {
		// 	bootMyTemplate();
		// });
	}
}
