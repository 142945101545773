<ul [class.d-block]="visibleMobileMenu">
	<li class="d-lg-none d-xl-block" sublink [routerLink]="['/']">
		<ng-container slot="text">Strona główna</ng-container>
	</li>

	<li sublink [routerLink]="['/news']">
		<ng-container slot="text">News</ng-container>
	</li>

	<ng-container *ngIf="authService.isAuthenticated()">
		<li sublink [routerLink]="['/event-list']">
			<ng-container slot="text">Wydarzenia</ng-container>
		</li>

		<li sublink [submenu]="true">
			<ng-container slot="text">Firmy</ng-container>
			<ng-container slot="submenu">
			<li sublink *ngFor="let g of groups" [routerLink]="['/organization-list', g.id]">
				<ng-container slot="text">{{ g.name }}</ng-container>
			</li>
			</ng-container>
		</li>
	</ng-container>

	<li sublink [submenu]="true" *ngIf="authService.isAdmin()">
		<ng-container slot="text">Administracja</ng-container>
		<ng-container slot="submenu">
			<li sublink [routerLink]="['/user-manager']">
				<ng-container slot="text">Zarządzanie użytkownikami</ng-container>
			</li>
			<li sublink [routerLink]="['/mail-schedule']">
				<ng-container slot="text">Zaplanowane maile</ng-container>
			</li>
			<li sublink [routerLink]="['/contact-list']">
				<ng-container slot="text">Wiadomości kontaktu</ng-container>
			</li>
			<li sublink [routerLink]="['/contact-request']">
				<ng-container slot="text">Prośby o kontakt</ng-container>
			</li>
			<li sublink [routerLink]="['/mail-transactional']">
				<ng-container slot="text">Maile Transakcyjne</ng-container>
			</li>
		</ng-container>
	</li>
	<li sublink [routerLink]="['/contact']">
		<ng-container slot="text">Kontakt</ng-container>
	</li>
</ul>