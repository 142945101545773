import { Injectable }      from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class VisibilityService {
	protected hiddenAttr;
	public visible = new BehaviorSubject<boolean>(true);

	constructor() {
		let visibilityChange;

		if (typeof document.hidden !== 'undefined') {
			this.hiddenAttr = 'hidden';
			visibilityChange = 'visibilitychange';
		}
		else if (typeof (document as any).msHidden !== 'undefined') {
			this.hiddenAttr = 'msHidden';
			visibilityChange = 'msvisibilitychange';
		}
		else if (typeof (document as any).webkitHidden !== 'undefined') {
			this.hiddenAttr = 'webkitHidden';
			visibilityChange = 'webkitvisibilitychange';
		}

		if (typeof document.addEventListener === 'undefined' || this.hiddenAttr === undefined) {
			console.log('Browser doesn\'t support Page Visibility API.');
			return;
		}

		// Handle page visibility change
		document.addEventListener(visibilityChange, this.handleVisibilityChange.bind(this), false);
	}

	handleVisibilityChange() {
		const newVisible = !document[this.hiddenAttr] as boolean;
		if (this.visible.value === newVisible) {
			return;
		}

		this.visible.next(newVisible);
	}
}