import { DataDirection } from '@class-hydrator/DataDirection';
import * as moment       from 'moment';

export function convertMoment(value: any, dataDirection: DataDirection) {
	switch (dataDirection) {
		case DataDirection.FromPlain:
			return value !== null ? moment.utc(value, moment.ISO_8601, true) : null;
		case DataDirection.FromDatabase:
			return value === null ? null : moment.utc(value);
		case DataDirection.ToPlain:
			return moment.isMoment(value) ? value.toISOString() : value;
		case DataDirection.ToDatabase:
			return moment.isMoment(value) ? value.toDate() : value;
	}

	return null;
}