import { Injectable }               from '@angular/core';
import { Router }                   from '@angular/router';
import { Hydrator }                 from '@class-hydrator/Hydrator';
import { moment, Moment }           from '@core/Model/Moment';
import { CoreHttp }                 from '@core/Service/CoreHttp';
import { EventManagerAsync }        from '@core/Service/EventManagerAsync';
import { LoginResponse }            from '@user/Interface/LoginResponse';
import { User }                     from '@user/Model/User';
import { BehaviorSubject, Subject } from 'rxjs';
import { config }                   from 'src/app/config';

@Injectable({ providedIn: 'root' })
export class AuthService {
	apiPrefix = '/api/auth';
	user: User = null;
	userSubject = new Subject<User>();

	token: string;
	tokenExpires: Moment;
	additionalData = new BehaviorSubject<any>(null);
	aem = new EventManagerAsync();

	expireTimer: number;

	constructor(
		protected coreHttp: CoreHttp,
		protected router: Router
	) {
		const auth = localStorage.getItem('auth');
		if (auth) {
			const d = JSON.parse(auth);
			this.user = d.user ? Hydrator.hydrate(User, d.user) : null;
			this.token = d.token;
			this.additionalData.next(d.additionalData ? d.additionalData : null);
			this.tokenExpires = d.tokenExpires ? moment.utc(d.tokenExpires) : null;
			this.setToken(d.token, d.tokenExpires);
		}
	}

	setToken(token: string, tokenExpires: string | moment.Moment) {
		this.token = token;
		if (tokenExpires) {
			this.tokenExpires = moment.isMoment(tokenExpires) ? tokenExpires : moment.utc(tokenExpires);
		}

		if (this.token && this.tokenExpires) {
			const now = moment.utc();
			if (this.tokenExpires.isBefore(now)) {
				this.clearToken();
				this.setLocalStorage();
			}
		}

		this.refreshLogoutTimer();
	}

	setUser(user: User) {
		this.user = user;
		this.userSubject.next(this.user);
	}

	refreshLogoutTimer() {
		if (!this.token || !this.tokenExpires) {
			return;
		}

		if (this.expireTimer) {
			clearTimeout(this.expireTimer);
		}

		const timeToExpire = this.tokenExpires.diff(moment.utc());
		this.expireTimer = setTimeout(this.expire.bind(this), timeToExpire);
	}

	async login(email: string, password: string) {
		const dataToSend: any = {
			email: email,
			password: password
		};

		await this.aem.fire('auth:beforeLogin', dataToSend);

		const response = await this
			.coreHttp
			.makeRequest<LoginResponse>(`${ config.apiUrl }${ this.apiPrefix }/login`, dataToSend);

		if (!response.success) {
			return false;
		}

		this.token = response.token;
		this.tokenExpires = moment.utc(response.expiresAt);

		const user = Hydrator.hydrate(User, response.user);
		this.setUser(user);
		this.additionalData.next(response.additionalData);

		this.setLocalStorage();
		this.refreshLogoutTimer();

		return true;
	}

	async logout() {
		if (!this.token) {
			return false;
		}

		const response = await this.coreHttp.makeRequest<LoginResponse>(
			`${ config.apiUrl }${ this.apiPrefix }/logout`, {
				token: this.token
			}
		);

		if (!response.success) {
			console.log('token did not exist');
		}

		this.clearToken();
		this.setLocalStorage();
		this.refreshLogoutTimer();

		await this.aem.fire('auth:afterLogout');
		return true;
	}

	async expire() {
		await this.logout();
		await this.router.navigateByUrl('/');
	}

	isAuthenticated() {
		return this.user !== null;
	}

	isAdmin() {
		if (this.user === null || !this.user.role) {
			return false;
		}

		return this.user.role === 'admin';
	}

	isModOrAdmin() {
		if (this.user === null || !this.user.role) {
			return false;
		}

		return this.user.role === 'admin' || this.user.role === 'mod';
	}

	protected setLocalStorage() {
		const d = {
			user: this.user ? Hydrator.dehydrate(this.user) : null,
			token: this.token,
			tokenExpires: this.tokenExpires ? this.tokenExpires.toISOString() : null,
			additionalData: this.additionalData.value
		};

		localStorage.setItem('auth', JSON.stringify(d));
	}

	protected clearToken() {
		this.user = null;
		this.token = null;
		this.tokenExpires = null;
		this.userSubject.next(this.user);
		this.additionalData.next(this.user);
	}
}