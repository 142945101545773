<section id="content" style="margin-bottom: 0px;">
	<div class="content-wrap">
		<div class="container clearfix">
			<div class="col_half nobottommargin">
				<div class="error404 center">404</div>
			</div>
			<div class="col_half nobottommargin col_last">
				<div class="heading-block nobottomborder">
					<h4>Ops, strona której szukasz nie istnieje</h4>
					<span>Spróbuj z linków podanych poniżej</span>
				</div>
				<div class="col_one_third widget_links nobottommargin inline-block">
					<ul>
						<li><a routerLink="/">Strona główna</a></li>
						<li *ngIf="auth.isAuthenticated()"><a routerLink="/profile">Profil</a></li>
						<li><a routerLink="/news">News</a></li>
					</ul>
				</div>
			</div>
		</div>
	</div>
</section>