
import { FieldType } from '@class-hydrator/Decorators';
import { moment }    from '@core/Model/Moment';

export type UserRole = 'admin' | 'mod' | 'user';

export class User {
	id: string = null;

	name = '';
	surname = '';
	email = '';
	plainPassword = '';

	address = '';
	city = '';
	zip = '';
	phone = '';
	groupId = null;

	socialMedia: { type: string, link: string }[] = [];
	role: UserRole = 'user';

	@FieldType({ type: 'moment' })
	dob: moment.Moment = null;

	isActive = false;
	isBanned = false;

	get fullName() { return this.name + ' ' + this.surname; }

	get dobText() {
		if (!this.dob) {
			return '';
		}

		return this.dob.clone().local().format('YYYY-MM-DD');
	}
}