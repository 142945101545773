import { moment, Moment }  from '@core/Model/Moment';

export class DateHelper {
	static FORMAT_FULL = 'YYYY-MM-DD HH:mm:ss';
	static FORMAT_DISPLAY = 'YYYY-MM-DD HH:mm';
	static FORMAT_DATE = 'YYYY-MM-DD';
	static FORMAT_ELAPSED = 'ago';

	static dateToString(date: Moment | string | Date, format = DateHelper.FORMAT_FULL) {
		if (date === null) {
			return '---';
		}

		let m;
		if (moment.isMoment(date)) {
			if (!date.isValid()) {
				return '---';
			}
			m = date.utc();
		}
		else {
			m = moment(date).utc();
		}

		if (format === DateHelper.FORMAT_ELAPSED) {
			return m.fromNow();
		}
		else {
			return m.format(format);
		}
	}

	static stringToDate(date: string | Moment) {
		if (moment.isMoment(date)) {
			return date.utc();
		}

		if (date === null || date === undefined) {
			return null;
		}

		return moment.utc(date);
	}
}
