import { Injectable, Injector }                      from '@angular/core';
import { moment }                                    from '@core/Model/Moment';
import { EventManagerAsync }                         from '@core/Service/EventManagerAsync';
import { FirebaseX }                                 from '@ionic-native/firebase-x/ngx';
import { Platform }                                  from '@ionic/angular';
import { NotificationModel, NotificationResolution } from '@notification/Model/NotificationModel';
import { PushNotificationService }                   from '@notification/Service/PushNotificationService';
import { AuthService }                               from '@user/Service/AuthService';
import * as firebase                                 from 'firebase/app';
import 'firebase/messaging';

@Injectable({ providedIn: 'root' })
export class FirebaseNotificationService {

	firebaseToken = '';
	messaging: firebase.messaging.Messaging;
	worker: ServiceWorkerRegistration;
	firebaseX: FirebaseX;

	em = new EventManagerAsync();

	constructor(
		protected auth: AuthService,
		protected platform: Platform,
		protected injector: Injector,
		protected pushNotifications: PushNotificationService
	) {}

	async init() {
		if (this.platform.is('cordova')) {
			this.firebaseX = this.injector.get(FirebaseX);

			this.auth.additionalData.subscribe(this.tokenSignIn.bind(this));
			// getting token from different source than desktop
			this.auth.aem.on('auth:beforeLogin', this.beforeLoginX.bind(this));
			this.auth.aem.on('auth:afterLogout', this.afterLogoutX.bind(this));

			this.firebaseX.getToken().then(token => console.log(`The token is ${ token }`));
			this.firebaseX.onMessageReceived().subscribe(data => console.log(`FCM message: ${ data }`));
		}
		else {
			// Web app
			if (Notification.permission === 'default') {
				await Notification.requestPermission();
			}

			firebase.initializeApp({
				apiKey: 'AIzaSyBDKQeY2yf28t6TF2ZlDj004tqcKpzYbAU',
				authDomain: 'wisla-biznes.firebaseapp.com',
				databaseURL: 'https://wisla-biznes.firebaseio.com',
				projectId: 'wisla-biznes',
				storageBucket: 'wisla-biznes.appspot.com',
				messagingSenderId: '444712679181',
				appId: '1:444712679181:web:b4f9db887009d87e62e244',
				measurementId: 'G-HE32FQL8RB'
			});

			this.messaging = firebase.messaging();
			try {
				this.worker = await navigator
					.serviceWorker
					.register('./assets/firebase-messaging-sw.js', { scope: '/assets/' })
				;
				await this.messaging.getToken({
					serviceWorkerRegistration: this.worker
				});
				// this.messaging.useServiceWorker(this.worker);

				this.auth.additionalData.subscribe(this.tokenSignIn.bind(this));
				this.auth.aem.on('auth:beforeLogin', this.beforeLogin.bind(this));
				this.auth.aem.on('auth:afterLogout', this.afterLogout.bind(this));

				this.messaging.onMessage(this.onMessage.bind(this));
				// navigator.serviceWorker.addEventListener('message', event => {
				// 	console.log('received noti from service worker', event.data);
				// 	this.onMessage(event.data);
				// });
			}
			catch (e) {
				console.log(e);
			}
		}
	}

	async beforeLogin(data: any) {
		try {
			const token = await this.messaging.getToken({
				serviceWorkerRegistration: this.worker
			});
			console.log(token);
			data.firebaseToken = token;
		}
		catch (e) {
			console.log(e);
		}
	}

	async beforeLoginX(data: any) {
		data.firebaseToken = await this.firebaseX.getToken();
	}

	async afterLogout() {
		try {
			await firebase.auth().signOut();
			// signed out
		} catch (e){
			console.log(e);
		}
	}

	async afterLogoutX() {
		try {
			await this.firebaseX.signOutUser();
			// signed out
		} catch (e){
			console.log(e);
		}
	}

	async tokenSignIn(data: any) {
		if (!data) {
			return;
		}
		console.log('try firebase', data);
		this.firebaseToken = data.firebaseToken;
	}

	/**
	 * This is for web app only
	 */
	async onMessage(...arg: any[]) {
		console.log('Received notification from firebase', ...arg);

		const notification = this.processNotification(arg[0]);
		const data = {
			resolution: NotificationResolution.SEND,
			notification: notification
		};

		// This way external modules can intercept notifications
		await this.em.fire('notification:before-send', data);

		// noinspection JSUnreachableSwitchBranches
		switch (data.resolution) {
			case NotificationResolution.FULL:
				await this.pushNotifications.notifyBrowser(notification);
				await this.pushNotifications.saveNotification(notification);
				break;
			case NotificationResolution.SEND:
				await this.pushNotifications.notifyBrowser(notification);
				break;
			case NotificationResolution.SAVE:
				await this.pushNotifications.saveNotification(notification);
				break;
		}
	}

	processNotification(raw: any) {
		if (!raw.data) {
			return null;
		}

		const d = raw.data;
		const n = new NotificationModel();
		n.id = d.id;
		n.type = d.type;
		n.createdAt = moment.utc(d.createdAt);
		n.isRead = d.isRead === 'true';
		n.title = d.title;
		n.name = d.name;
		n.message = d.message;
		n.userId = d.userId;
		try {
			n.data = JSON.parse(d.data);
		}
		catch (e) {
			n.data = null;
		}

		return n;
	}
}