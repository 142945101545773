import { DataDirection } from '@class-hydrator/DataDirection';

export function convertNumber(value: any, dataDirection: DataDirection) {
	switch (dataDirection) {
		case DataDirection.FromPlain:
			return value === null ? null : +value;
		case DataDirection.FromDatabase:
			return value === null ? null : +value;
		case DataDirection.ToPlain:
			return value === null ? null : +value;
		case DataDirection.ToDatabase:
			return value === null ? null : +value;
	}
}