import { Injectable }        from '@angular/core';
import { Hydrator }          from '@class-hydrator/Hydrator';
import { GenericResponse }   from '@core/Interface/GenericResponse';
import { moment }            from '@core/Model/Moment';
import { Timer }             from '@core/Model/Timer';
import { CoreHttp }          from '@core/Service/CoreHttp';
import { Platform }          from '@ionic/angular';
import { VisibilityService } from '@notification/Service/VisibilityService';
import { NotificationModel } from '@notification/Model/NotificationModel';
import { BehaviorSubject }   from 'rxjs';
import { config }            from 'src/app/config';

@Injectable({ providedIn: 'root' })
export class PushNotificationService {
	notifications = new BehaviorSubject<NotificationModel[]>([]);
	timer = new Timer('notification');
	muted = new BehaviorSubject<boolean>(false);

	constructor(
		protected platform: Platform,
		protected coreHttp: CoreHttp,
		protected visibilityService: VisibilityService
	) {
		(window as any).muteNotifications = this.mute.bind(this);
		(window as any).unmuteNotifications = this.unmute.bind(this);

		const t = localStorage.getItem('notifications-muted');
		if (t) {
			this.muted.next(JSON.parse(t));
		}

		this.muted.subscribe(v => {
			localStorage.setItem('notifications-muted', JSON.stringify(v));
			console.log('muted changed ', v);
		});
	}

	async initialize() {
		if (this.platform.is('cordova')) {
			return;
		}

		if (Notification.permission === 'default') {
			await Notification.requestPermission();
		}
	}

	mute(forMinutes?: number) {
		if (forMinutes) {
			const t = moment();
			t.add(forMinutes, 'minute');
			// t.add(forMinutes, 'second');
			this.timer.scheduleAt('unmuteNotifications', t);
		}

		console.log('muted notifications');
		this.muted.next(true);
	}

	unmute() {
		this.muted.next(false);
		this.timer.cancel();
		console.log('unmuted notifications');
	}

	loadNotifications(notifications: any[]) {
		if (this.muted.value) {
			return;
		}

		this.notifications.next(
			Hydrator.hydrateArray(NotificationModel, notifications)
		);
	}

	newNotification(notification: any) {
		const newNotification = Hydrator.hydrate(NotificationModel, notification);
		if (this.visibilityService.visible.value) {
			console.log('application is visible, no need to open notification');
		}
		else {
			console.log('application is not visible');
			this.notify(newNotification).catch(console.error);
		}
	}

	async notify(notification: NotificationModel) {
		if (!this.platform.is('cordova')) {
			await this.notifyBrowser(notification);
		}
	}

	// noinspection JSMethodCanBeStatic
	async notifyBrowser(notification: NotificationModel) {
		if (this.muted.value) {
			await this.saveNotification(notification);
			return;
		}

		// Let's check if the browser supports notifications
		if (!('Notification' in window)) {
			console.log('This browser does not support desktop notification');
			return;
		}

		let permission;
		switch (Notification.permission) {
			case 'default':
				permission = await Notification.requestPermission();
				if (permission === 'denied') {
					return;
				}
			// tslint:disable-next-line:no-switch-case-fall-through
			case 'granted':
				const noti = new Notification(notification.title, {
					body: notification.message,
					timestamp: notification.createdAt.unix()
				});
				return;
			case 'denied':
				return;
		}
	}

	async saveNotification(notification: NotificationModel) {
		this.notifications.value.push(notification);
		this.notifications.next(this.notifications.value);
	}

	async markAllAsRead() {
		const response = await this
			.coreHttp
			.makeRequest<GenericResponse>(config.apiUrl + `/api/noti/read-all`, {}, 'post');

		this.notifications.next([]);
	}

	async markAsRead(id: string) {
		const response = await this
			.coreHttp
			.makeRequest<GenericResponse>(config.apiUrl + `/api/noti/read`, { notificationId: id }, 'post');

		const notifications = this.notifications.value;
		const idx = notifications.findIndex(n => n.id === id);
		if (idx > -1) {
			notifications.splice(idx, 1);
			this.notifications.next(notifications);
		}

		return response.success;
	}
}