import { Component }   from '@angular/core';
import { AuthService } from '@user/Service/AuthService';

@Component({
	templateUrl: 'PageNotFoundComponent.html'
})
export class PageNotFoundComponent {
	constructor(
		public auth: AuthService
	) {
	}
}