import { Component, ElementRef, HostBinding, HostListener } from '@angular/core';
import { Router, Event, NavigationStart }                   from '@angular/router';
import { NotificationModel }                                from '@notification/Model/NotificationModel';
import { PushNotificationService }                          from '@notification/Service/PushNotificationService';

@Component({
	selector: 'notifications',
	templateUrl: 'NotificationsComponent.html'
})
export class NotificationsComponent {
	notification: NotificationModel[] = [];
	@HostBinding('class.top-cart-open') visibleNotifications = false;

	constructor(
		public pushNotifications: PushNotificationService,
		protected element: ElementRef,
		protected router: Router
	) {
		this.pushNotifications.notifications.subscribe((v: NotificationModel[]) => {
			this.notification = v;
		});

		this.router.events.subscribe((e: Event) => {
			if (e instanceof NavigationStart) {
				this.visibleNotifications = false;
			}
		});
	}

	@HostListener('click', ['$event'])
	onHostClick($event: MouseEvent) {
		$event.preventDefault();
		$event.stopPropagation();
		this.visibleNotifications = !this.visibleNotifications;
	}

	@HostListener('document:click')
	onDocumentClick() {
		this.visibleNotifications = false;
	}

	async markAllAsRead($event: MouseEvent) {
		$event.preventDefault();
		await this.pushNotifications.markAllAsRead();
	}

}